import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cross from "../../../assets/images/symbols/cross_grey.svg";
import plus from "../../../assets/images/symbols/plus.svg";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Input from "../../../components/Input/Input";
import TextArea from "../../../components/TextArea/TextArea";
import cn from "classnames";
import LessonApi from "../../../utils/api/LessonApi";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import { toast } from "react-toastify";
import { getTypeText, getTypeImage, questionsTypesWithoutExtraAnswers, RATING_TEN, RATING_FIVE, STARS_ANSWER_CODE, VIDEO_ANSWER_CODE, WRITTEN_ANSWER_CODE, TRUE_FALSE_ANSWER_CODE, SEQUENCE_ANSWER_CODE, FILE_UPLOAD_ANSWER_CODE, RATE_ANSWER_CODE, COMPARE_ANSWER_CODE, MULTIPLE_CHOICE_ANSWER_CODE } from "../../../utils/questionsHelper";
import { v4 as uuidv4 } from 'uuid';
import DraggingArrows from "../../../components/DraggingArrows/DraggingArrows";
import axios from "axios";
import SomeAnswerInputs from "./SomeAnswerInputs";
import ReactPlayer from 'react-player';
import AnswerStarsOption from "./AnswerStarsOption";
import CommentsBlock from "./CommentsBlock";
import Dropdown from "../../../components/Dropdown/Dropdown";
import VideoAnswer from "./VideoAnswer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MAX_VIDEO_SIZE } from "../../../constants/courses";
import { debounce } from "lodash";
import { DELAY_ON_INPUTS_CHANGE } from "../../../utils/coursesHelper";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const QuestionItem = ({ question, questionsWithoutDelete, setQuestions, questionIndex, handleInputsDataChange, isDragging, setChangedLessonFlag, setIsFieldEmpty, isFieldEmpty, defaultQuestion, course, editLanguage, isNotDefaultLanguage }) => {

   const [uploadVideoProgress, setUploadVideoProgress] = useState(false);

   const t = useFindTranslationsByString();

   const getNonDeletedIndex = (id) => questionsWithoutDelete.findIndex((q) => {
      if(q.id) return q.id === id
      if(q.uniqueId) return q.uniqueId === id
   });

   // Content property is actually a title for each question apart from type 9 (video answer)
   // In video answer it's title property since content is for file link there.
   const [inputsData, setInputsData] = useState({
      content: question?.content ? question?.content : '',
      answers: question?.answers ? question?.answers : [],
      chosenFileType: '',
      isShuffled: false,
      ...(question?.description ? { description: question?.description } : {}),
      ...(question?.comment ? { comment: question?.comment } : {}),
      ...(question?.title ? { title: question?.title } : {}),
      ...(question.rating && { rating: question.rating }),
      ...(question.sequence && { sequence: question.sequence }),
      ...(question.filetype && question?.type !== VIDEO_ANSWER_CODE && { filetype: question.filetype }),
      ...(question?.options?.length > 0 && { options: question.options }),
   });

   function addAnswer() {
      setChangedLessonFlag("changed")
      const newAnswer = { id: uuidv4(), content: "", isCorrect: 0 };
      const newAnswerAlwaysCorrect = { id: uuidv4(), content: "", isCorrect: 1 };

      // Add the new answer to the existing answers array
      const updatedAnswers = inputsData?.options?.length > 0 || inputsData?.sequence?.length > 0
         ? [...inputsData.answers, newAnswerAlwaysCorrect]
         : [...inputsData.answers, newAnswer];

      let updatedOptions = null;

      if (inputsData?.options?.length > 0) {
         updatedOptions = [...inputsData.options,''];
      }

      // Update the inputsData state with the new answers array
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: updatedAnswers,
         ...(inputsData?.options?.length > 0 ? { options: updatedOptions } : {})
      }));
   }

   const onSelectAnswer = (id) => {
      setChangedLessonFlag("changed");
      setIsFieldEmpty(false);
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData.answers.map((answer) =>
            answer.id === id
               ? { ...answer, isCorrect: !answer.isCorrect }
               : answer
         ),
      }));
   };

   const onChangeAnswerText = (text, id) => {
      setChangedLessonFlag("changed");
      setIsFieldEmpty(false);
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: prevInputsData?.answers?.map((answer) =>
            answer.id === id ? { ...answer, content: text } : answer
         ),
      }));
   };

   const onSelectAnswerRadioStars = (rating) => {
      setIsFieldEmpty(false);
      setChangedLessonFlag("changed");
      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         rating: rating
      }));
   };

   const clearAnswersAndKeepFirst = () => {
      setIsFieldEmpty(false);
      setChangedLessonFlag("changed");
      const firstAnswer = inputsData?.answers[0];

      const firstOption = inputsData?.options?.length > 0 && inputsData?.options[0];
      const firstSequence = inputsData?.sequence?.length > 0 && inputsData?.sequence[0];

      setInputsData((prevInputsData) => ({
         ...prevInputsData,
         answers: [firstAnswer],
         ...(inputsData?.sequence?.length > 0 ? { sequence: [firstSequence]} : {}),
         ...(inputsData?.options?.length > 0 ? { options: [firstOption] } : {}),
      }));
   };

   const removeAnswerById = (id, index = null) => {
      setChangedLessonFlag("changed");
      setInputsData((prevInputsData) => {
        // Check if there are more than one answers
        if (prevInputsData.answers.length > 1) {
          return {
            ...prevInputsData,
            answers: prevInputsData.answers.filter((answer) => answer.id !== id),
            options: prevInputsData?.options?.length && prevInputsData?.options.filter((item, idx) => idx !== index),
            sequence: prevInputsData?.sequence?.length && prevInputsData?.sequence.filter((item, idx) => idx !== index)
          };
        } else {
          // If there's only one answer, don't remove it, return the previous state as it is
          return prevInputsData;
        }
      });
    };

   const addDeleteQuestionId = () => {
      setChangedLessonFlag("changed");
      if (question.id) {
         setQuestions(prev => {
           const updatedQuestions = prev.map(q => {
             if (q.id === question.id) {
               return {
                 ...q,
                 delete_question_id: question.id,
               };
             }
             return q;
           });
     
           return updatedQuestions;
         });
       } else {
         setQuestions(prev => {
           const updatedQuestions = prev.map(q => {
             if (q.uniqueId === question.uniqueId) {
               return {
                 ...q,
                 delete_question_id: null,
               };
             }
             return q;
           });
     
           return updatedQuestions;
         });
       }
   };

   const onChangeTitle = (e) => {
      setChangedLessonFlag("changed");
      setIsFieldEmpty(false);
      setInputsData({ ...inputsData, content: e?.target?.value });
   };

   const onChangeVideoQuestionTitle = (e) => {
      setChangedLessonFlag("changed")
      setIsFieldEmpty(false);
      setInputsData({ ...inputsData, title: e?.target?.value });
   };

   const onChangeDesc = (e) => {
      setChangedLessonFlag("changed")
      setInputsData({ ...inputsData, description: e.target.value });
   };

   const onChangeComment = (e) => {
      setChangedLessonFlag("changed")
      setInputsData({ ...inputsData, comment: e.target.value });
   };

   const onClearTitleDesc = () => {
      setChangedLessonFlag("changed")
      setInputsData({ ...inputsData, content: "", description: "" });
   };

   const debouncedHandleInputsDataChange = useCallback(
      debounce((data, index) => {
         if (handleInputsDataChange) {

            handleInputsDataChange(data, index);
         }
      }, DELAY_ON_INPUTS_CHANGE),
      []
   );

   useEffect(() => {
      debouncedHandleInputsDataChange(inputsData, questionIndex);
    
      return () => {
        debouncedHandleInputsDataChange.cancel();
      };
    }, [inputsData, questionIndex]);

    const handleAutoSubmit = async (file, remove) => { 
      if (file.size > MAX_VIDEO_SIZE) {
        remove();
        return toast(<ToastMsg text={'File size exceeds the maximum allowed size of 500 MB.'} isError />);
      }

      const dataToGetUrl = [{
                              question_id: question?.id,
                              filename: file.name
                           }]
         
      const res = await new LessonApi().getPresignedQuestionFilesUploadUrls(dataToGetUrl);

      const uploadUrl = res?.success?.data?.[0]?.success?.data?.upload_signed_url;
      const amazonVideoUrl = res?.success?.data?.[0]?.success?.data?.file_signed_url;

      const resUpload = await axios({
         method: 'PUT',
         url: uploadUrl,
         headers: {
           "Content-Type": file.type,
         },
         data: file,
         onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setUploadVideoProgress(`${progress}%`)
            if(progress === 100) setUploadVideoProgress('')
          },
       }).catch(err => {
         remove();
         toast(<ToastMsg text={err?.message || 'Something went wrong'} isError />)
       });

       if(resUpload) {
         setChangedLessonFlag("changed"); 
         setInputsData((prevInputsData) => ({
            ...prevInputsData,
            content: amazonVideoUrl,
         }));
       }
    }

    const handleChangeStatus = ({ meta, file, remove }, status) => {
      if(status === 'done') {
         handleAutoSubmit(file, remove);
      } 
    }

   return (
      <div className={cn(
         styles.question, isDragging ? styles.dragging : '',
         question?.delete_question_id ? styles.deleted_question : '', 
         question.delete_question_id === null ? styles.deleted_question : '',
         question?.type === MULTIPLE_CHOICE_ANSWER_CODE && 
         isFieldEmpty && 
         inputsData?.answers?.every(ans => !ans.isCorrect) ? styles.error : ''
      )}>
         <div className={`${styles.header} ${isDragging ? styles.dragging : ''}`}>
            <div className="my_flex_center">
               <DraggingArrows isDragging={isDragging}/>
               <span className={styles.question_num}>
                  {getNonDeletedIndex(question.id ? question.id : question.uniqueId) >= 0 
                     ? 
                        getNonDeletedIndex(question.id ? question.id : question.uniqueId) + 1
                     :
                       ''
                  }
               </span> 
            </div>
            <div className={styles.header_title}>
               <img className={styles.question_icon} src={getTypeImage(question?.type)} alt="icon" />
               <span className={styles.question_type_text}>{t(getTypeText(question?.type))}</span>
            </div>
            {!isNotDefaultLanguage &&
             <img onClick={addDeleteQuestionId} src={cross} alt={""} className={cn(styles.header_cross)} />  
            }
         </div>

         <div className={styles.content}>
            <div className={styles.content_block}>
               <span className={styles.clear} onClick={onClearTitleDesc}>
                  {t("Clear all")}
               </span>
               <div className={cn(
                  styles.title,
                  inputsData?.title === defaultQuestion?.title && editLanguage?.code !== course?.defaultLanguage ? styles.prevInput : '',
               )}>
                  {question.type !== VIDEO_ANSWER_CODE &&
                    <TextArea
                        isResize
                        minRows={1.5}
                        height={50}
                        title={t("Title and description")}
                        placeholder={t("Enter a title")}
                        withHeader
                        value={inputsData?.content}
                        onChangeValue={onChangeTitle}
                        hasError={isFieldEmpty && !inputsData?.content?.length}
                        isGrayText={inputsData?.content === defaultQuestion?.content && editLanguage?.code !== course?.defaultLanguage}
                     />
                  }
                  {question.type === VIDEO_ANSWER_CODE &&
                    <TextArea
                        isResize
                        minRows={1.5}
                        title={t("Title and description")}
                        placeholder={t("Enter a title")}
                        withHeader
                        value={inputsData?.title}
                        onChangeValue={onChangeVideoQuestionTitle}
                        hasError={isFieldEmpty && !inputsData?.title?.length}
                        isGrayText={inputsData?.title === defaultQuestion?.title && editLanguage?.code !== course?.defaultLanguage}
                     />
                  }
              
               </div>
               <TextArea
                  isResize
                  minRows={4}
                  placeholder={t("Enter a description")}
                  height={66}
                  value={inputsData?.description}
                  onChangeValue={onChangeDesc}
                  isGrayText={inputsData?.description === defaultQuestion?.description && editLanguage?.code !== course?.defaultLanguage}
               />
            </div>

            <div className={styles.content_block}>
               {!questionsTypesWithoutExtraAnswers.includes(question?.type) && 
                  <>
                     <span
                        className={styles.clear}
                        onClick={clearAnswersAndKeepFirst}
                     >
                        {t("Clear all")}
                     </span>

                     <span className={styles.answers}>{t("Answers")}</span>
                  </>
               }

               <div className={
                  cn(
                     styles.answers_container, 
                     question.type === STARS_ANSWER_CODE ? styles.flexed : '',
                     question.type === TRUE_FALSE_ANSWER_CODE ? styles.flexed : '',
                  )}>
                     {question?.type === VIDEO_ANSWER_CODE && 
                        <VideoAnswer
                           setChangedLessonFlag={setChangedLessonFlag}
                           inputsData={inputsData}
                           setInputsData={setInputsData}
                           uploadVideoProgress={uploadVideoProgress}
                           handleChangeStatus={handleChangeStatus}
                           onChangeComment={onChangeComment}
                           question={question}
                        />
                     }
                  
                     {/* FOR FUTURE {question?.type === FILE_UPLOAD_ANSWER_CODE && 
                        <>
                           <div className={styles.section_title}>File options</div>
                           <Dropdown
                              placeholder={'Select'}
                              data={inputsData?.filetype}
                              title={'File format'}
                              value={inputsData?.chosenFileType}
                              onChange={(value) => setInputsData((prevInputsData) => ({
                                 ...prevInputsData,
                                 chosenFileType: value,
                              }))}
                           />
                        </>
                     } */}

            <Droppable droppableId={`${question?.id ? question?.id : question?.uniqueId}`} type="answers_group">
                  {(provided, snapshot) => (
                     <div {...provided.droppableProps} ref={provided.innerRef} className={cn(
                        question.type === TRUE_FALSE_ANSWER_CODE ? styles.flex : '',
                     )}>
                        {inputsData.answers?.map((item, answerIdx) => (
                           <Draggable
                              key={item?.id}
                              draggableId={`${item?.id}`}
                              index={answerIdx}
                           >
                              {(provided, snapshot) => (
                                 <div
                                    id="additional_draggable"
                                    ref={provided.innerRef}
                                    className={cn(
                                       question.type === TRUE_FALSE_ANSWER_CODE ? styles.flex : '',
                                       question.type === COMPARE_ANSWER_CODE ? styles.flex : '',
                                    )}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                 >
                                    <SomeAnswerInputs 
                                       removeAnswerById={removeAnswerById}
                                       key={answerIdx}
                                       inputsData={inputsData}
                                       setInputsData={setInputsData}
                                       onChangeAnswerText={onChangeAnswerText}
                                       onSelectAnswer={onSelectAnswer}
                                       type={question?.type} 
                                       item={item} 
                                       answerIdx={answerIdx} 
                                       setChangedLessonFlag={setChangedLessonFlag}
                                       isFieldEmpty={isFieldEmpty}
                                       setIsFieldEmpty={setIsFieldEmpty}
                                       isGrayText={item?.content === defaultQuestion?.answers?.[answerIdx]?.content && editLanguage?.code !== course?.defaultLanguage || false}
                                       isGrayTextOption={inputsData?.options?.[answerIdx] === defaultQuestion?.options?.[answerIdx] && editLanguage?.code !== course?.defaultLanguage || false}
                                       isNotDefaultLanguage={isNotDefaultLanguage}
                                    />
                           </div>
                           )}
                        </Draggable>
                        ))}
                        {provided.placeholder}
                     </div>
                  )}
             </Droppable>

                  {question?.type === STARS_ANSWER_CODE &&
                        <>
                           <AnswerStarsOption
                              id={`${question.id}_rating_5`}
                              label="From 1 to 5 points"
                              checked={inputsData?.rating === RATING_FIVE}
                              onChange={() => onSelectAnswerRadioStars(RATING_FIVE)}
                           />
                           <AnswerStarsOption
                              id={`${question.id}_rating_10`}
                              label="From 1 to 10 points"
                              checked={inputsData?.rating === RATING_TEN}
                              onChange={() => onSelectAnswerRadioStars(RATING_TEN)}
                           />
                        </>
                     }

                     {question?.type === WRITTEN_ANSWER_CODE && 
                        <CommentsBlock commentValue={inputsData?.comment} onChangeComment={onChangeComment} />
                     }
               </div>

               {!questionsTypesWithoutExtraAnswers.includes(question?.type) &&  !isNotDefaultLanguage &&
                  <div className={styles.add} onClick={addAnswer}>
                     <img src={plus} alt={""} />
                     {t("Add answer")}
                  </div>
               }
            </div>
         </div>
      </div>
   );
};

export default React.memo(QuestionItem);
