import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styles from "./styles.module.scss";
import Roles from "../../../utils/api/Roles";
import {
   REQUESTED_STATUS_REJECT,
   REQUESTED_STATUS_ACCEPT,
   USER_STATUS_ACTIVE,
   USER_STATUS_BANNED,
} from "../../../constants/user";
import UserApi from "../../../utils/api/UserApi";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import StatisticsBodyItem from "./StatisticsBodyItem";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import BranchApi from "../../../utils/api/BranchApi";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import { useSelector } from "react-redux";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";

const StatisticsTable = ({
   columns,
   data,
   searchedValue,
   tableFor,
   loading,
   maxHeight,
   maxWordsLength = 19,
   lastElementRef,
   minWidth,
   rowClickable,
   t,
   isProgressTable,
   onSetOrder,
   onSetOrderBy,
   order,
   activeCourseFilter
}) => {
   const handleSort = (column) => {
      const columnMappings = {
         "rating": { orderBy: "average_rating", orderToggle: true },
         "rating_from_users": { orderBy: "average_rating", orderToggle: true },
         "course_name": { orderBy: "course_name", orderToggle: true },
         "created": { orderBy: "created_at", orderToggle: true },
         "last_modified": { orderBy: "updated_at", orderToggle: true },
         "available": { orderBy: isProgressTable ? "active_users_with_access" : "users_access_count", orderToggle: true },
         "company": { orderBy: "company_name", orderToggle: true },
         "not_started": { orderBy: "users_not_started_course", orderToggle: true },
         "ongoing": { orderBy: "users_started_course", orderToggle: true },
         "failed": { orderBy: isProgressTable ?  "users_failed_course" : "incorrect_answer_count", orderToggle: true },
         "completed": { orderBy: "users_completed_course", orderToggle: true },
         "course_score": { orderBy: "course_score", orderToggle: true },
         "true": { orderBy: "approved_answer_count", orderToggle: true },
         "false": { orderBy: "incorrect_answer_count", orderToggle: true },
         "partially": { orderBy: "partially_approved_answer_count", orderToggle: true },
         "pass": { orderBy: "approved_answer_count", orderToggle: true },
         "on_rework": { orderBy: "partially_approved_answer_count", orderToggle: true },
         "test_name": { orderBy: "lesson_name", orderToggle: true },
         "question_name": { orderBy: "question_content", orderToggle: true },
         "certificate_id": { orderBy: "certificate_serial_number", orderToggle: true },
         "course": { orderBy: "course_name", orderToggle: true },
         "courses": { orderBy: "course_names", orderToggle: true },
         "company_name": { orderBy: "company_name", orderToggle: true },
         "student_amount": { orderBy: "student_amount", orderToggle: true },
         "percentage": { orderBy: "result", orderToggle: true },
         "team_name": { orderBy: "team_names", orderToggle: true },
         "user": { 
            orderBy: tableFor === "users" 
                      ? "name" 
                      : tableFor === "integration" 
                        ? "user_first_name" 
                        : "student_first_name", 
            orderToggle: true 
          },
         "user_score": { orderBy: "company_user_score", orderToggle: true },
         "integration": { orderBy: "integration_percentage", orderToggle: true },
         "progress": { orderBy: "course_progress", orderToggle: true },
         "first_online": { orderBy: "first_login", orderToggle: true },
         "registration": { orderBy: "registered_at", orderToggle: true },
         "last_online": { orderBy: "last_activity", orderToggle: true },
         "available_from": { orderBy: "available_from", orderToggle: true },
         "first_visit": { orderBy: "first_visit", orderToggle: true },
         "assigned_courses": { orderBy: "available_courses_count", orderToggle: true },
         "completed_courses": { orderBy: "completed_courses_count", orderToggle: true },
      };

      const columnMapping = columnMappings[column];

      if (columnMapping) {
         const { orderBy, orderToggle } = columnMapping;

         onSetOrderBy(orderBy || "");
         
         if (orderToggle) {
            onSetOrder(order === "asc" ? "desc" : "asc");
         }
      }
   };

   const navigate = useNavigate();

   return (
      <div style={{ maxHeight }} className={styles.table_container}>
         <table className={cn(styles.table, minWidth ? styles.minWidth : '')}>
            <thead className={styles.thead}>
               <tr>
                  {columns?.map((column, index) => (
                     <th
                        key={column}
                        onClick={() => handleSort(column)}
                        className={cn(
                            styles.header, 
                            'noselect', isProgressTable && index === 0 ? styles.progress_course_name : '')
                        }
                     >
                        
                        <div className={styles.column_name_text}>
                           { column === 'completed' && isProgressTable && 
                              <div className={styles.completed_circle}>
                              </div>
                           }
                           {  column === 'failed' && isProgressTable &&
                              <div className={styles.failed_circle}>
                              </div>
                           }
                           {  column === 'ongoing' && isProgressTable &&
                              <div className={styles.ongoing_circle}>
                              </div>
                           }
                          {column === "percentage"
                              ? "%"
                              : column === "user_score" && activeCourseFilter?.id && tableFor === 'users'
                                 ? t?.returnTranslation("Grade")
                                 : t?.returnTranslation(column.replaceAll('_', ' '))
                          }
                        </div>
                        <div className={styles.arrow_wrapper}>
                           <span className={styles.arrow}></span>
                        </div>

                        { column === "user_score" && (tableFor === 'users' || tableFor === 'company') && !activeCourseFilter?.id &&
                           <span className={styles.hint}>{t?.usersAverageScoreForAllCompletedCourses}</span>
                        }

                        { column === "user_score" && tableFor === 'users' && activeCourseFilter?.id && 
                           <span className={styles.hint}>{t?.returnTranslation('Average score for all completed tests in a course')}</span>
                        }

                        { column === "course_score" && 
                           <span className={styles.hint}>{t?.averageScoreOfUsersThatHaveBeenPassedThisCourse}</span>
                        }
                     </th>
                  ))}
               </tr>
            </thead>
            <tbody className={styles.tbody}>
               <TransitionGroup component={null}>
                  {data?.map((row, index) => (
                     <CSSTransition
                        key={`row-${index}`}
                        classNames="fade"
                        timeout={100}
                     >
                        <>
                           <StatisticsBodyItem
                              tableFor={tableFor}
                              row={row}
                              columns={columns}
                              index={index}
                              maxWordsLength={maxWordsLength}
                              rowClickable={rowClickable}
                              t={t}
                              isProgressTable={isProgressTable}
                           />
                        </>
                     </CSSTransition>
                  ))}
               </TransitionGroup>
               <tr className={styles.triggerRefetch} ref={lastElementRef ? lastElementRef : null}></tr>
            </tbody>
         </table>

         {data?.length === 0 && <EmptyBlock />}
      </div>
   );
};

export default StatisticsTable;
